import React from "react"

const Footer = () => {
  return (
    <footer className="footer-container">
      <a
        href="https://github.com/myrdstom/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <p className="content">Designed and Built by Paul Kayongo</p>
      </a>
    </footer>
  )
}

export default Footer
